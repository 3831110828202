import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import * as FileSaver from 'file-saver';
import { ToastrService } from 'ngx-toastr';
import { Globle } from 'src/app/Shared/global';
import { OnlineRegisterService } from 'src/Services/onlineRegister.services';
@Component({
  selector: 'app-online-registartion-import',
  templateUrl: './online-registartion-import.component.html',
  styleUrls: ['./online-registartion-import.component.css']
})
export class OnlineRegistartionImportComponent implements OnInit {
  file: File;
  isShowprogressSpinner: boolean;
  blockedDocument: boolean = false;
  constructor(private globle: Globle, private onlineRegisterService: OnlineRegisterService, private toastr: ToastrService,
     private titleService: Title, private router: Router) {
    this.titleService.setTitle("Online Registration Import");
  }

  ngOnInit() {
  }

  onFileSelect(file) {
    this.file = file.target.files[0];
  }
  onFileUpload() {
    this.blockDocument();
    let formData = new FormData();
    if (this.file)
      formData.append("file", this.file);
    this.onlineRegisterService.UploadExcelFile(this.globle.cUser.id, formData).subscribe(res => {
      this.file = undefined;
      if (res) {
        if (res.status == "Success") {
          document.getElementById("uploadFile").textContent = "";
          if (res.responseNewFilename) {
            this.onlineRegisterService.DownloadDocument(this.globle.cUser.authToken, { path: res.responseNewFilename }).subscribe(
              fileData => {
                this.unblockDocument();
                if (fileData != null) {
                  FileSaver.saveAs(fileData, res.responseNewFilename);
                  this.toastr.success(res.message, res.status);
                  this.router.navigate(['admin/online/register/user']);
                }
              }, error => {
                this.unblockDocument();
              });
          }
          else {
            this.unblockDocument();
            this.toastr.success(res.message, res.status);
            this.router.navigate(['admin/online/register/user']);
          }

        }
        else {
          this.toastr.error(res.message, res.status);
          this.unblockDocument();
        }
      }
      else
        this.unblockDocument();
    }, error => {
      this.unblockDocument();
    })
  }
  blockDocument() {
    this.isShowprogressSpinner = true;
    this.blockedDocument = true;
  }
  unblockDocument() {
    this.isShowprogressSpinner = false;
    this.blockedDocument = false;
  }

}
