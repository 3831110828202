import { Component, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Globle } from 'src/app/Shared/global';
import { monthlyreportFilterModel } from 'src/Models/monthlyreportFilterModel';
import { SnapshotandSummaryReportModel } from 'src/Models/SnapshotandSummaryReportModel';
import { UsersService } from 'src/Services/users.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ElementRef } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormBuilder } from '@angular/forms';
import * as FileSaver from 'file-saver';
import { UniversalService } from 'src/Services/universal.service';
import { Inject } from '@angular/core';

@Component({
  selector: 'app-snapshot-summary-report',
  templateUrl: './snapshot-summary-report.component.html',
  styleUrls: ['./snapshot-summary-report.component.css']
})
export class SnapshotandSummaryReportComponent implements OnInit {
  filterModel = new monthlyreportFilterModel();
  exportFilterModel = new monthlyreportFilterModel();
  listOfSnapshotandSummaryReport: any = [];
  listOfExports: SnapshotandSummaryReportModel[];
  listOfExportsCount: any = [];
  totalRecordsCount: number = 0;
  isAscending: boolean;
  isloading = false;
  recordsCount: number = -1;
  currentPage: number = 1;

  dropdownListYear = [];
  selectedItemsYear = [];
  dropdownSettingsYear = {};
  InrollVSNladCount: any = [];
  listOfmonthandyear: any = [];
  upDownmonthandyearCSS: string = '';
  upDowntotalactivesubscribersCSS: string = '';

  constructor(private _fb: FormBuilder, private router: Router, private userService: UsersService, private titleService: Title,
    private globle: Globle, private toastr: ToastrService) {
    this.filterModel.pageNumber = 1;
    this.filterModel.pageSize = 10;
    this.filterModel.monthandyear = "";
    this.filterModel.orderBy = 'monthandyear desc';
    sessionStorage.setItem('snapshotPeriod', '');
    sessionStorage.setItem('monthandyear', '');
    this.titleService.setTitle('Summary and Detail Subscriber Snapshot Report');
  }

  ngOnInit() {
    this.GetMonthlySnapshotandSummaryByPaged();
    this.GetInrollVSNlad();
    this.GetMonthandYearList();

    this.dropdownSettingsYear = {
      singleSelection: false,
      text: 'Filter by Snapshot Period',
      enableSearchFilter: true,
      classes: 'dropdownborders',
      noDataLabel: 'No Year available',
      showCheckbox: true,
      enableFilterSelectAll: false,
      disabled: false,
      escapeToClose: true,
      badgeShowLimit: 1,
    };
  }

  GetInrollVSNlad() {
    this.userService.GetInrollVSNlad().subscribe(
      data => {
        this.InrollVSNladCount = data;
      },
      error => {
        console.log(error);
      }
    );
  }

  InrollvsNladList() {
    this.router.navigateByUrl(`admin/nlad-vs-inroll-report`);
  }

  removeElemetninArray(array, element) {
    return array.filter(e => e !== element);
  }

  GetMonthandYearList() {
    this.userService.GetMonthandYearList().subscribe(
      data => {
        if (data != null) {
          for (let iCounter = 0; iCounter < data.length; iCounter++) {
            this.dropdownListYear.push({ 'id': data[iCounter].id, 'itemName': data[iCounter].itemName })
          }
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  onItemSelectYear($event) {
    this.filterModel.pageNumber = 1;
    this.currentPage = 1;
    if (this.listOfmonthandyear != null) {
      if (this.listOfmonthandyear.length > 0) {
        this.listOfmonthandyear = this.removeElemetninArray(this.listOfmonthandyear, $event.id)
        this.listOfmonthandyear.push($event.id);
      }
      else {
        this.listOfmonthandyear.push($event.id);
      }
    }
    this.filterModel.monthandyear = this.listOfmonthandyear.join(',');
    this.GetMonthlySnapshotandSummaryByPaged();
  }

  OnItemDeSelectYear($event) {
    this.filterModel.pageNumber = 1;
    this.currentPage = 1;
    if (this.listOfmonthandyear != null) {
      if (this.listOfmonthandyear.length > 0) {
        this.listOfmonthandyear = this.removeElemetninArray(this.listOfmonthandyear, $event.id)
      }
    }
    this.filterModel.monthandyear = this.listOfmonthandyear.join(',');
    this.GetMonthlySnapshotandSummaryByPaged();
  }

  onSelectAllYear($event) {
    this.filterModel.pageNumber = 1;
    this.currentPage = 1;
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfmonthandyear != null) {
        if (this.listOfmonthandyear.length > 0) {
          this.listOfmonthandyear = this.removeElemetninArray(this.listOfmonthandyear, $event[iCounter].id)
          this.listOfmonthandyear.push($event[iCounter].id);
        }
        else {
          this.listOfmonthandyear.push($event[iCounter].id);
        }
      }
    }
    this.filterModel.monthandyear = this.listOfmonthandyear.join(',');
    this.GetMonthlySnapshotandSummaryByPaged();
  }

  onDeSelectAllYear($event) {
    this.filterModel.pageNumber = 1;
    this.currentPage = 1;
    this.listOfmonthandyear = [];
    this.filterModel.monthandyear = '';
    this.GetMonthlySnapshotandSummaryByPaged();
  }

  GetMonthlySnapshotandSummaryByPaged() {

    this.isloading = true;
    this.listOfSnapshotandSummaryReport = [];

    this.userService.GetMonthlySnapshotandSummaryByPaged(this.filterModel).subscribe(
      data => {
        this.exportFilterModel = Object.assign({}, this.filterModel);
        this.exportFilterModel.pageSize = 100000;
        this.exportFilterModel.pageNumber = 1;

        this.isloading = false;
        this.listOfSnapshotandSummaryReport = data;
        if (this.listOfSnapshotandSummaryReport != null) {
          if (this.listOfSnapshotandSummaryReport.length > 0) {
            this.totalRecordsCount = this.listOfSnapshotandSummaryReport[0].totalRecordCount;
            this.recordsCount = this.totalRecordsCount;
          }
          else {
            this.totalRecordsCount = 0;
            this.recordsCount = 0;
          }
        }
        else {
          this.totalRecordsCount = 0;
          this.recordsCount = 0;
        }
      },
      error => {
        this.isloading = false;
        this.recordsCount = 0;
        console.log(error);
      }
    );
  }

  ViewDetails(snapshotPeriod, monthandyear) {
    sessionStorage.setItem('snapshotPeriod', snapshotPeriod);
    sessionStorage.setItem('monthandyear', monthandyear);
    this.router.navigateByUrl(`admin/snapshot-summary-details-report`);
  }

  ExportDetails(snapshotPeriod) {
    this.isloading = true;
    this.listOfExports = [];

    this.exportFilterModel.pageNumber = 1;
    this.exportFilterModel.pageSize = 100000;
    this.exportFilterModel.orderBy = 'snapshotPeriod desc';
    this.exportFilterModel.monthandyear = snapshotPeriod;

    this.userService.GetSnapshotandSummaryByPaged(this.exportFilterModel).subscribe(
      data => {
        this.listOfExports = data;
        setTimeout(this.Export, 3000);
        this.isloading = false;
      },
      error => {
        console.log(error);
      }
    );
  }

  Export() {
    $('#idexportToExcel')[0].click();
  }

  exportToExcel() {
    let tableData = document.getElementById("ExportTable").innerHTML;

    var uri = 'data:application/vnd.ms-excel;base64,'
      , template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--><meta http-equiv="content-type" content="text/plain; charset=UTF-8"/></head><body>{table}</body></html>'
      , base64 = function (s) { return window.btoa(unescape(encodeURIComponent(s))) }
      , format = function (s, c) { return s.replace(/{(\w+)}/g, function (m, p) { return c[p]; }) }
    let html = "<table><tr class='mainhead'> <td colspan='3'>";
    html += tableData;
    html += "</td></tr></table>";
    let name = 'SummaryandDetailSubscriberSnapshotReport';
    var ctx = { worksheet: name || 'Worksheet', table: html }
    FileSaver.saveAs((uri + base64(format(template, ctx))), "SummaryandDetailSubscriberSnapshotReport.xls");
  }

  ExportDetailsCount() {
    this.isloading = true;
    this.listOfExportsCount = [];
    this.exportFilterModel.monthandyear = this.filterModel.monthandyear;
    this.exportFilterModel.pageSize = 100000;
    this.exportFilterModel.pageNumber = 1;
    this.userService.GetMonthlySnapshotandSummaryByPaged(this.exportFilterModel).subscribe(
      data => {
        this.listOfExportsCount = data;
        setTimeout(this.ExportCount, 3000);
        this.isloading = false;
      },
      error => {
        console.log(error);
      }
    );
  }

  ExportCount() {
    $('#idexportToExcelCount')[0].click();
  }

  exportToExcelCount() {
    let tableData = document.getElementById("ExportTableCount").innerHTML;

    var uri = 'data:application/vnd.ms-excel;base64,'
      , template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--><meta http-equiv="content-type" content="text/plain; charset=UTF-8"/></head><body>{table}</body></html>'
      , base64 = function (s) { return window.btoa(unescape(encodeURIComponent(s))) }
      , format = function (s, c) { return s.replace(/{(\w+)}/g, function (m, p) { return c[p]; }) }
    let html = "<table><tr class='mainhead'> <td colspan='3'>";
    html += tableData;
    html += "</td></tr></table>";
    let name = 'SummaryandDetailSubscriberSnapshotReportCount';
    var ctx = { worksheet: name || 'Worksheet', table: html }
    FileSaver.saveAs((uri + base64(format(template, ctx))), "SummaryandDetailSubscriberSnapshotReportCount.xls");
  }


  sort(sortBy: any) {
    this.cleanCssClass();
    this.isAscending = !this.isAscending;
    this.filterModel.orderBy = this.isAscending ? sortBy : sortBy + ' DESC';

    switch (sortBy) {
      case 'monthandyear': this.upDownmonthandyearCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case 'totalactivesubscribers': this.upDowntotalactivesubscribersCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      default: break;
    }
    this.GetMonthlySnapshotandSummaryByPaged();
  }

  cleanCssClass() {
    this.upDownmonthandyearCSS = '';
    this.upDowntotalactivesubscribersCSS = '';
  }

  pageChanged($event) {
    this.filterModel.pageNumber = $event.page;
    this.GetMonthlySnapshotandSummaryByPaged();
  }
}