import { BaseModel } from './baseModel';

export class UserModel extends BaseModel {
  firstName: string;
  lastName: string;
  middleName?: string;
  emailId?: string;
  ssn?: string;
  userName: string;
  password?: string;
  userGroupId: number;
  lastLoginDate?: string;
  isActive: string;
  saltKey?: string;
  providerId?: number;
  userTypeId?: number;
  authToken?: string;
  enrollmentNumber?: string;
  subscriberId?: number;
  customerId: string;
  locationId: string;
  locationCode: string;
  locationName: string;
  streetAddress: string;
  aptName: string;
  stateId: number;
  city: string;
  zipcode: string;
  stateName: string;
  modifiedByName: string;
  chkAgreement?: boolean;
  stateShortName: string;
  totalRecordCount: string;
  getAlert: string;
  applicantPageMessage: string;
  currentenvironment: string;
  isTribalLand: any;  
  onlineRegistrationID:any;
  phone:any;
  applicantPageErrMessage: string;
}
