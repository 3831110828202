import { Params, NavigationEnd } from "@angular/router";
import { AbstractControl, FormControl, ValidatorFn, ValidationErrors } from "@angular/forms";
import { StepStatus } from "src/Models/stepStatus";
import { AES, enc } from 'crypto-ts';
import { SubscriberApplicarionStage } from "src/Models/SubscriberMaster.model";

export function movetotop() {
  const scrollToTop = window.setInterval(() => {
    const pos = window.pageYOffset;
    if (pos > 0) {
      window.scrollTo(0, pos - 20); // how far to scroll on each step
    } else {
      window.clearInterval(scrollToTop);
    }
  }, 1);
}

export function getParam(param, id) {
  let value = '';
  param.subscribe((params: Params) => {
    if (params[id]) {
      value = params[id];
    }
  });
  return value;
}

export function checkContainesURL(router, value) {
  return router.url.indexOf(value) !== -1;
}


export function getIntParam(param, id) {
  let value = 0;
  param.subscribe((params: Params) => {
    if (params[id]) {
      value = params[id];
    }
  });
  return value;
}

export function getDayMonthYear(year: number, month: number) {
  const days: number[] = [];
  const years: number[] = [];
  const y = new Date().getUTCFullYear();
  const numberOfDays = getDaysInMonth(month, year);
  for (let i = y; i >= y - 70; i--) {
    years.push(i);
  }
  for (let i = 1; i <= numberOfDays; i++) {
    days.push(i);
  }
  return { years, days };
}

function getDaysInMonth(month, year) {
  return new Date(year, month, 0).getDate();
}

export function ValidateIntital(value: string): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    if (!control.value) { return null; }
    if (value.toLowerCase() === control.value.toLowerCase()) {
      return null;
    }
    return { 'invalid': true };
  };
}

export function checkAllStepCompleted(stepStatus: StepStatus, recertify: Boolean) {
  if (stepStatus.personalDetails && stepStatus.address && stepStatus.aggrement
    && stepStatus.qualify && (stepStatus.dependent || recertify)) {
    return true;
  }
  return false;
}

export function enumValue(enumtype): Array<string> {
  const keys = Object.keys(enumtype);
  return keys.slice(keys.length / 2);
}

export function copyToClipboard(toCopy: string): void {
  const create_copy = (e: ClipboardEvent) => {
    e.clipboardData.setData('text/plain', toCopy);
    e.preventDefault();
  };
  document.addEventListener('copy', create_copy);
  document.execCommand('copy');
  document.removeEventListener('copy', create_copy);
}


export function getEncryptedString(value: string, key: string) {
  var result = AES.encrypt(value, key).toString();
  return result.replace(/\//g, "-12-");
}

export function getDecryptedString(value: string, key: string) {
  var bytes = AES.decrypt(value.replace(/-12-/g, "/"), key);
  return bytes.toString(enc.Utf8);
}

export function getEncryptedObject(data: any, key: string) {
  var result = AES.encrypt(JSON.stringify(data), key);
  return result;
}

export function getDecryptedObject(data: string, key: string) {
  var bytes = AES.decrypt(data, key);
  return JSON.parse(bytes.toString(enc.Utf8));
}



export function getIncompletedStep(applicationStatus: SubscriberApplicarionStage, appType: number) {
  
  if (!applicationStatus.isPersonalDetailsCompleted) {
    return 'personal-details';
  }
  else if (!applicationStatus.isAddressCompleted) {
    return 'address';
  }
  else if (!applicationStatus.isDependentCompleted && appType == 1) {
    return 'dependent';
  }
  else if (!applicationStatus.isDependentCompleted && appType == 3) {
    return 'dependent';
  }
  else if (!applicationStatus.isQualifiedCompleted) {
    return 'qualify';
  }
  else if (!applicationStatus.isAgreementCompleted) {
    return 'agreement';
  }
  else if (!applicationStatus.isReviewCompleted) {
    return 'review';
  }
  else if (!applicationStatus.isReviewCompleted) {
    return 'review';
  }
  else if (!applicationStatus.isOtherCompleted) {
    return 'other';
  }
}



export function checkStepIsCompleted(appStage: string, applicationStatus: SubscriberApplicarionStage, appType: number) {
  
  if (appStage == 'personal-details' && applicationStatus.isPersonalDetailsCompleted) {
    return true;
  }
  else if (appStage == 'address' && applicationStatus.isAddressCompleted) {
    return true;
  }
  else if (appStage == 'dependent' && applicationStatus.isDependentCompleted && appType == 1) {
    return true;
  }
  else if (appStage == 'dependent' && applicationStatus.isDependentCompleted && appType == 3) {
    return true;
  }
  else if (appStage == 'qualify' && applicationStatus.isQualifiedCompleted) {
    return true;
  }
  else if (appStage == 'agreement' && applicationStatus.isAgreementCompleted) {
    return true;
  }
  else if (appStage == 'other' && applicationStatus.isOtherCompleted) {
    return true;
  }
  return false;
}

export function checkAllStepIsCompleted(applicationStatus: SubscriberApplicarionStage, appType: number) {
  
  if (appType == 1 && applicationStatus.isPersonalDetailsCompleted && applicationStatus.isAddressCompleted && applicationStatus.isDependentCompleted && applicationStatus.isQualifiedCompleted && applicationStatus.isAgreementCompleted)
    return true;
  else if (appType == 2 && applicationStatus.isPersonalDetailsCompleted && applicationStatus.isAddressCompleted && applicationStatus.isQualifiedCompleted && applicationStatus.isAgreementCompleted)
    return true;
  else  if (appType == 3 && applicationStatus.isPersonalDetailsCompleted && applicationStatus.isAddressCompleted && applicationStatus.isDependentCompleted && applicationStatus.isQualifiedCompleted && applicationStatus.isAgreementCompleted)
    return true;
  return false;
}

export function getAppTypeRoute(appType: number, appStatus: number) {  
  if (appType == 1 && appStatus == 1) {
    return '/acp/register/';
  }
  else if (appType == 2 && (appStatus == 5 || appStatus == 1)) {
    return '/acp/recertify/';
  }
  else if (appType == 3 && appStatus == 1) {
    return '/acp/transferin/';
  }
  else {
    return '/acp/home';
  }
}

export function alphabetAndDotOnly(text): boolean {
  var regex = /^[a-zA-Z. ]*$/;
  var isValid = regex.test(text);
  if (isValid)
    return true;
  return false;
}

export function alphabetOnly(text): boolean {
  var regex = /^[a-zA-Z ]*$/;
  var isValid = regex.test(text);
  if (isValid)
    return true;
  return false;
}