import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { Globle } from 'src/app/Shared/global';
import { SubscribersModel } from 'src/Models/subscribersModel';
import { UsersService } from 'src/Services/users.service';
import { UserModel } from 'src/Models/usersModel';
import { getIntParam, copyToClipboard } from 'src/app/Shared/commonMethods';
import { ToastrService } from 'ngx-toastr';
import { StateService } from 'src/Services/state.service';

@Component({
  selector: 'app-manage-subscriber',
  templateUrl: './manage-subscriber.component.html',
  styleUrls: ['./manage-subscriber.component.css']
})
export class ManageSubscriberComponent implements OnInit {
  id: number = 0;
  userModel = new UserModel();
  providerId = 0;
  userForm: FormGroup;

  dropdownSettings = {};
  selectedStates = [];
  listOfStateIds: any;
  states = [];

  constructor(private _fb: FormBuilder,
    private stateService: StateService,
    private toastr: ToastrService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private userService: UsersService,
    private titleService: Title,
    private globle: Globle,) {
    this.titleService.setTitle('Manage Subscribers');
    this.dropdownSettings = {
      singleSelection: true,
      labelKey: "name",
      primaryKey: "id",
      text: "Select state",
      enableSearchFilter: true,
      classes: "dropdownborders",
      noDataLabel: 'No location available',
      showCheckbox: false,
      enableFilterSelectAll: false,
      disabled: false,
      escapeToClose: true
    };

  }

  ngOnInit() {
    
    this.id = getIntParam(this.activatedRoute.params, 'id');
    this.providerId = this.globle.cUser.providerId;

    this.userForm = this._fb.group({
      id: [this.id],
      providerId: [this.providerId],     
      isActive: [true],
      getAlert: [true],
    });

    if (this.id > 0)
      this.GetSubscriberById();
  }

  GetSubscriberById() {
    this.userService.GetSubscriberById(this.id).subscribe(
      data => {
        this.userModel = data;

        if (this.userModel != null) {
          this.userForm.setValue({
            id: this.userModel.id,
            providerId: this.userModel.providerId,          
            isActive: this.userModel.isActive,
            getAlert: this.userModel.getAlert
          });
          this.selectedStates = [];
          this.selectedStates.push({ 'id': this.userModel.stateId, 'name': this.userModel.stateName });
          this.listOfStateIds = [];
          this.listOfStateIds.push(this.userModel.stateId);
          this.getState();
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  getState(): void {
    this.stateService.GetStateList().subscribe(
      data => {
        
        let listOfStates = data;
        if (listOfStates != null) {
          if (listOfStates.length > 0) {
            if (data != null) {
              for (let iCounter = 0; iCounter < data.length; iCounter++) {
                this.states.push({ 'id': data[iCounter].id, 'name': data[iCounter].name })
              }
            }
          }
        }
      }
    );
  }

  isValidMailFormat(control: FormControl) {
    //let EMAIL_REGEXP = /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i;
    let EMAIL_REGEXP = /^[a-z0-9_.]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i;

    if (control.value != "" && (control.value.length <= 5 || !EMAIL_REGEXP.test(control.value))) {
      return { "Please provide a valid email": true };
    }
    return null;
  }

  onSelectState($event) {
    this.listOfStateIds = this.removeElemetninArray(this.listOfStateIds, $event.id)
    this.listOfStateIds.push($event.id);
  }

  onDeSelectState($event) {
    if (this.listOfStateIds != null) {
      if (this.listOfStateIds.length > 0) {
        this.listOfStateIds = this.removeElemetninArray(this.listOfStateIds, $event.id)
      }
    }
  }

  onSelectAll($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfStateIds != null) {
        if (this.listOfStateIds.length > 0) {
          this.listOfStateIds = this.removeElemetninArray(this.listOfStateIds, $event[iCounter].id)
          this.listOfStateIds.push($event[iCounter].id);
        }
        else {
          this.listOfStateIds.push($event[iCounter].id);
        }
      }
    }
  }

  onDeSelectAllState($event) {
    this.listOfStateIds = [];
  }

  removeElemetninArray(array, element) {
    return array.filter(e => e.id != element);
  }

  saveUsers(userForm: any) {
    this.userModel = userForm.value;
    this.userForm.value['providerId'] = this.providerId;

    // if (this.userModel.emailId == '' || this.userModel.emailId == null || typeof (this.userModel.emailId) == 'undefined') {
    //   this.toastr.error('Please enter email', 'Error');
    //   return;
    // }
    // 
    // if (this.userModel.emailId != '') {
    //   let result = this.isValidMailFormat(userForm.controls.emailId);
    //   if (result != null) {
    //     this.toastr.error('Please provide a valid email', 'Error');
    //     return;
    //   }
    // }

    if (this.listOfStateIds.length == 0) {
      this.toastr.error('Please select state', 'Error');
      return;
    }
    this.userModel.stateId = this.listOfStateIds.join(',');
    this.userModel.modifiedById = this.globle.cUser.id;
    this.userModel.createdById = this.globle.cUser.id;

    this.userService.UpdateSubscriber(this.userModel).subscribe(
      data => {
        let id = data;
        
        if (id == -1) {
          this.toastr.error('Duplicate Email Found', 'Error');
        }
        else if (id > 0) {
          this.toastr.success('Subscriber updated successfully', 'Success');
          this.returnToList();
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  returnToList() {
    this.router.navigate(['/admin/subscribers']);
  }

}
