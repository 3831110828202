
export class SubscriberMasterModel {

    /**
     *
     */
    constructor() {
        this.subscriberApplicarionStage = new SubscriberApplicarionStage();
        this.subscriberApplicationHistory = new SubscriberApplicationHistory();
        this.subscriberPersonalDetails = new SubscriberPersonalDetails();
        this.subscriberAddress = new SubscriberAddress();
        this.subscriberDependent = new SubscriberDependent();
        this.subscriberQualify = new SubscriberQualify();
        this.subscriberAgreement = new SubscriberAgreement();
        this.subscriberOthers = new SubscriberOthers();
    }
    subscriberApplicarionStage: SubscriberApplicarionStage;
    subscriberApplicationHistory: SubscriberApplicationHistory;
    subscriberPersonalDetails: SubscriberPersonalDetails;
    subscriberAddress: SubscriberAddress;
    subscriberDependent: SubscriberDependent;
    subscriberQualify: SubscriberQualify;
    subscriberAgreement: SubscriberAgreement;
    subscriberOthers: SubscriberOthers;
}

export class SubscriberApplicationHistory {
    id: number;
    applicationId: string;
    userId?: number;
    appType?: number;
    appStatus?: number;
    approvalDate?: Date;
    responseData: string;
    createdDate?: Date;
    createdById?: number;
    modifiedDate?: Date;
    modifiedById?: number;
    recertifyMsg?: string;
    isInterestedInHardware?: boolean = false;
    notes?: any = '';
    accessKey?: string;
    APBAccount: string;
    isconsent?:boolean;
}

export class SubscriberApplicarionStage {
    isPersonalDetailsCompleted: boolean;
    isAddressCompleted: boolean;
    isDependentCompleted: boolean;
    isQualifiedCompleted: boolean;
    isAgreementCompleted: boolean;
    isReviewCompleted: boolean;
    isAllStepCompleted: boolean;
    isOtherCompleted: boolean;
}

export class SubscriberPersonalDetails {

    firstName: string;
    middleName: string;
    lastName: string;
    // suffix: string;
    phoneNo: string;
    dateofBirth: Date | string;
    emailId: string;
    //commType: string;
    sSN: string;
    tIN: string;
    enrollDate: string;
    customerId: string;
    locationCode: string;
    locationId: string;
    zone: string;
    applicationNumber: string;
    eligibilityExpirationDate: string;
    transactionEffectiveDate: string;
    serviceInitializationDate: string;
    onlineRegistrationID: string;
    isEnrolledInNLAD: string;
    isClickOnApplyHere: boolean = false;
    isAlreadyEnrolledRadioChecked: boolean = false;
    accountNumber: any = '';
    isInterestedInHardware: boolean = false;
    notes: string;
    isAlternateId: boolean = false;
    //schoolName: string = '';
    isConsumerFee: boolean = false;
    isAddETI: boolean = false;
    ConsentDateTime: string;
    consentZoneId: string;
}

export class SubscriberAddress {
    streetNumber: string;
    //aptName: string;
    city: string;
    stateId?: number;
    zipcode: string;
    stateShortName: string;

    streetNumberTemp: string;
    //aptNameTemp: string;
    cityTemp: string;
    stateIdTemp?: number;
    zipcodeTemp: string;
    onTribalLand: string;
    addressType: string;
    stateShortNameTemp: string;
    isTribalLands?: boolean;
}

export class SubscriberDependent {
    isDependent: boolean
    firstName?: string;
    lastName?: string;
    middleName?: string;
    //suffix?: string;
    dateofBirth?: any;
    sSN?: string;
    tIN?: string;
    isAlternateId: boolean = false;
}

export class SubscriberQualify {
    qualifyType: number;
    sNAP: boolean;
    medicalId: boolean;
    sSI: boolean;
    fPHA: boolean;
    vP: boolean;
    //tSP: boolean;
    bIA: boolean;
    tribalTANF: boolean;
    fDPIR: boolean;
    tribalHS: boolean;
    eBI: boolean; // Rakesh
    isTribalSpecific: boolean;
    pEASA: boolean;
    sLBA: boolean;
    fPG: boolean;
    sLOI: boolean;
    eLIPCovid19: boolean;
    wIC: boolean;
    sCEP: boolean;
    
    publicHousingCode:string;
    //liveHouseHold: boolean;
    //incomeSameorLess: boolean;
}

export class SubscriberAgreement {
    agreementIndex1: string;
    agreementIndex2: string;
    agreementIndex3: string;
    agreementIndex4: string;
    agreementIndex5: string;
    agreementIndex6: string;
    agreementIndex7: string;
    agreementIndex8: string;
    agreementIndex9: string;
    agreementSignature: string;
}

export class SubscriberOthers {
    deviceReimbursementDate: Date | string;
    deviceType: string;
    deviceMake: string;
    expectedRateDevice: string;
    schoolName: string;
    latitude: string;
    longitude: string;
    expectedRate: string;
}
