import { Component, OnInit } from '@angular/core';
import { getIntParam, movetotop, checkContainesURL } from 'src/app/Shared/commonMethods';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ToastrService } from 'src/app/Shared/toastr.service';
import { Globle } from 'src/app/Shared/global';
import { FormBuilder, FormGroup } from '@angular/forms';
import { filterParameter } from 'src/Models/filterParameter';
import { UniversalService } from 'src/Services/universal.service';
import { EligibilityCheckResponse } from 'src/Models/eligibilityCheckResponseModel';
import { UserType, ProviderUserGroup } from 'src/app/Shared/AppEnums';
import { Title } from '@angular/platform-browser';
import { trimTrailingNulls } from '@angular/compiler/src/render3/view/util';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-eligibility-check-status',
  templateUrl: './eligibility-check-status.component.html',
  styleUrls: ['./eligibility-check-status.component.css']
})
export class EligibilityCheckStatusComponent implements OnInit {
  providerId = 0;
  fromProvider = false;
  userType = 0;
  emailTemplateForm: FormGroup;
  model = new filterParameter();
  eligibilityCheckResponse = new EligibilityCheckResponse();

  isDataLoad: boolean = false;
  isRecordFound: boolean = false;
  isloading: boolean = false;
  enrollJSON: any;
  errorModel: any;

  isCustomerIdTextbox: boolean = false;
  isEmailTextbox: boolean = true;

  customerIdTextboxValue: string = '';
  emailTextboxValue: string = '';
  chkEmail: string = '';

  constructor(
    private universalService: UniversalService,
    private _fb: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private toastr: ToastrService,
    private globle: Globle,
    private titleService: Title,
  ) {
    this.titleService.setTitle('Check Status');
    this.emailTemplateForm = this._fb.group({
      customerId: '',
      email: '',
      userType: 'Email'
    });
  }

  ngOnInit() {
    this.fromProvider = checkContainesURL(this.router, 'providers');
    this.providerId = getIntParam(this.activatedRoute.params, 'pid');
    this.userType = getIntParam(this.activatedRoute.params, 'usertype');
    this.setProvider();

    this.chkEmail = sessionStorage.getItem('chkEmail');
    if (this.chkEmail != '' && this.chkEmail != null) {
      this.customerIdTextboxValue = this.chkEmail;
      setTimeout(() => {
        this.adminEligibilityCheck(this.emailTemplateForm, 'auto');
      }, 200);
      setTimeout(() => {
        sessionStorage.removeItem('chkEmail');
      }, 1000);
    }
  }
  setProvider() {
    if (!this.fromProvider) {
      this.providerId = this.globle.cUser.providerId;
      this.userType = this.globle.cUser.userTypeId
    }
  }

  adminEligibilityCheck(emailTemplateForm: any, actioncall: string) {
    this.isDataLoad = false;

    if (this.isCustomerIdTextbox &&
      (emailTemplateForm.controls['customerId'].value == null || emailTemplateForm.controls['customerId'].value.toString().trim() == '')) {
      this.toastr.Error('Please enter customerid', 'Error');
      return;
    }

    if (this.chkEmail != '' && this.chkEmail != null && actioncall == 'auto') {
      this.model.emailId = this.chkEmail;
    }
    else {
      if (this.isEmailTextbox) {
        if (emailTemplateForm.controls['email'].value.toString().trim() == '') {
          this.toastr.Error('Please enter email', 'Error');
          return;
        }

        if (emailTemplateForm.value['email'].trim() != '') {
          let result = this.isValidMailFormat(emailTemplateForm.controls.email);
          if (result != null) {
            this.toastr.Error('Please provide a valid email', 'Invalid Email');
            return;
          }
        }
        this.model.emailId = emailTemplateForm.controls['email'].value;
      }
      else
        this.model.emailId = '';
    }

    if (this.isCustomerIdTextbox) {
      if (emailTemplateForm.controls['customerId'].value == '') {
        this.toastr.Error('Please enter customer Id', 'Error');
        return;
      }
      this.model.accountNumber = emailTemplateForm.controls['customerId'].value;
    }

    else
      this.model.accountNumber = '';

    this.isloading = true;
    this.universalService.AdminEligibilityCheck(this.model).subscribe(
      data => {
        this.isloading = false;
        if (data != null) {
          if (data.obj != null) {
            if (data.obj.errorModel != null) {
              this.errorModel = data.obj.errorModel;
            }
          }

          if (data.eligibility['enrollJSON'] != null) {
            this.enrollJSON = JSON.parse(data.eligibility['enrollJSON']);
          }
          this.eligibilityCheckResponse = data.eligibility;
          this.isDataLoad = true;

          this.isRecordFound = true;
        }
        else {
          this.isRecordFound = false;
          this.isDataLoad = true;
        }

      },
      error => {
        this.isloading = false;
      }
    );
  }

  setEliglibityColor(status: any) {

    if (status == 'Complete') {
      return 'clsSuccessEnrollColor';
    }
    else {
      return 'clsErrorEnrollColor';
    }
  }

  setEnrollColor(status: any) {

    if (status == 'Approved') {
      return 'clsSuccessEnrollColor';
    }
    else if (status == 'Transferred IN') {
      return 'clsSuccessEnrollColor';
    }
    else {
      return 'clsErrorEnrollColor';
    }
  }

  ShowHideTextbox($event) {
    if ($event.currentTarget.value == 'Email') {
      this.isEmailTextbox = true;
      this.isCustomerIdTextbox = false;
      this.customerIdTextboxValue = '';
    }
    else {
      this.isEmailTextbox = false;
      this.isCustomerIdTextbox = true;
      this.emailTextboxValue = '';
    }
  }

  removeElemetninArray(array, element) {
    return array.filter(e => e != element);
  }

  isValidMailFormat(control: FormControl) {
    let EMAIL_REGEXP = /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i;

    if (control.value != '' && (control.value.length <= 5 || !EMAIL_REGEXP.test(control.value))) {
      return { 'Please provide a valid email': true };
    }
    return null;
  }
}
