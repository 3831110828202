import { Component } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { UsersService } from 'src/Services/users.service';
import { movetotop } from './Shared/commonMethods';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'app';

  constructor(private userService: UsersService) {

  }

  ngOnInit() {
    
  }

}
