import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Http, Response, ResponseContentType } from '@angular/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  constructor(private http: HttpClient, private _http: Http) { }

  post(path: string, body: object = {}): Observable<any> {
    return this.http.post(`${environment.apiURL}api/${path}`, JSON.stringify(body));
  }

  postFromData(path: string, data: FormData): Observable<any> {
    return this.http.post(`${environment.apiURL}api/${path}`, data);
  }

  get(path: string, id: number = 0): Observable<any> {
    const url = `${environment.apiURL}api/${path}` + (id !== 0 ? `/${id}` : ``);
    return this.http.get(url);
  }

  getFile(path: string, id: number, fileType: string): Observable<any> {
    return this._http.get(`${environment.apiURL}api/${path}/` + id, { responseType: ResponseContentType.Blob });
  }

  getBypassURL(path: string): Observable<any> {
    const url = `${path}`;
    return this._http.get(url);
  }

  filePostFromData(path: string, data: FormData, httpOptions): Observable<any> {    
    return this.http.post(`${environment.apiURL}api/${path}`, data, httpOptions);
  }

  downloadFile(path, model: any): Observable<any> {
    return this._http.post(`${environment.apiURL}api/${path}/`, model, { responseType: ResponseContentType.Blob });
  }
  
}
